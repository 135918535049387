import React from "react";
import { Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";

const JokerWrapper = styled("span")(
  ({ theme, isActive, isButton, isDisabled }) => ({
    fontSize: "1.35rem",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: isButton ? (isActive ? theme.shadows[1] : "none") : "none",
    transition: "all 0.2s ease-in-out",
    filter: isActive ? "none" : "grayscale(100%)",
    cursor: isButton ? (isDisabled ? "not-allowed" : "pointer") : "default",
    border: isButton
      ? isActive
        ? "2px ridge gold"
        : "1.5px ridge black"
      : "none",
    "&:hover":
      isButton && !isDisabled
        ? {
            transform: "scale(1.05)",
            boxShadow: theme.shadows[2],
          }
        : {},
    verticalAlign: "middle",
    lineHeight: 1,
  })
);

const JokerIcon = ({ isActive, isButton, isDisabled, onClick }) => {
  const joker = (
    <JokerWrapper
      isActive={isActive}
      isButton={isButton}
      isDisabled={isDisabled}
      onClick={isButton && !isDisabled ? onClick : undefined}
      as={isButton ? "button" : "span"}
      disabled={isButton && isDisabled}
    >
      🃏
    </JokerWrapper>
  );

  return (
    <Tooltip title="Double-Point Game" arrow>
      {joker}
    </Tooltip>
  );
};

export default JokerIcon;
