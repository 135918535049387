import React from "react";
import { styled } from "@mui/material/styles";

const RulesContainer = styled("div")({
  maxWidth: "800px",
  margin: "0 auto",
  padding: "20px",
  textAlign: "left",
});

const ruleSets = {
  default: {
    title: "Default Tournament Rules",
    rules: [
      {
        title: "General Rules",
        content: `
          <p>These are the default rules for all tournaments.</p>
          <p>Please check back later for specific rules for this tournament.</p>
        `
      }
    ]
  },
  1: {
    title: "2024 Paris Olympic Basketball Prediction Rules",
    rules: [
      {
        title: "General Rules",
        content: `
          <p>For every game in the 2024 Paris Olympic Games basketball tournament, every user will be expected to make three predictions:</p>
          <ul>
            <li>Winner of the game</li>
            <li>Winner of the game against the spread</li>
            <li>The top scoring player of the game</li>
          </ul>
          <p>Example of the spread: if a game is listed as Team A -3.5 vs. Team B +3.5, Team A will have to win by more than 3.5 points and Team B can not lose by more than 3.5 points for the user to score points. Of course, Team B winning the game outright will score points for the user. In case of a tie for the top scorer for a game, all tied players will score points.</p>
        `
      },
      {
        title: "Scoring",
        content: `
          <ul>
            <li>Each correct prediction for the winner, the winner against the spread and the top scorer will result in 1 point for the user.</li>
            <li>For one game each round, the user can activate the joker card (🃏) which will double the points for that game.</li>
            <li>All the points in the playoff rounds will be doubled.</li>
          </ul>
          <p>The joker card for the round can be changed as many times as needed before the game with the joker card starts. If five out of the six games for a round have started but no joker card has been picked yet, the user can still use it for the sixth game.</p>
        `
      },
      {
        title: "Fair Play",
        content: `
          <p>Every user is expected to behave honorably and with the highest moral character inside and outside the game during the tournament. Failure to do so will result in disqualification from the game.</p>
        `
      },
      {
        title: "Emojis",
        content: `
          <p>You may see all sorts of emojis during the tournament.</p>
          <ul>
            <li>🃏 - Played the joker card for the game</li>
            <li>🎯 - Predicted everything in the game correctly</li>
            <li>💎 - Predicted everything correctly in a joker card game</li>
            <li>💤 - Received 0 points for the game</li>
            <li>💩 - Received 0 points for a joker card game</li>
            <li>🦄 - Made a unique prediction</li>
            <li>🍻 - Made a popular prediction</li>
            <li>🤷 - Made a logically inconsistent prediction</li>
            <li>🤯 - Only user to receive points for the game</li>
            <li>😟 - Only user to not receive points for the game</li>
            <li>🔥 - On fire!</li>
            <li>🇦🇺 🇧🇷 🇨🇦 🇫🇷 🇩🇪 🇬🇷 🇯🇵 🇵🇷 🇷🇸 🇸🇸 🇪🇸 🇺🇸 - is the leading point-getter for selected country</li>
            <li>🎖️ - Won a round</li>
          </ul>
        `
      },
      {
        title: "Croissant Game 🥐",
        content: `
          <p>To celebrate the hosts of the Olympic Games, France, during the tournament, any user can send any other user virtual croissants by clicking on the croissant emoji on their profile.</p>
        `
      }
    ]
  },
  5: {
    title: "2024 Formula 1 Prediction Game Rules",
    rules: [
      {
        title: "General Rules",
        content: `
          <p>For every race in the 2024 Formula 1 season, every user will be expected to make two predictions per F1 team:</p>
          <ul>
            <li>The driver to finish ahead of his teammate</li>
            <li>The driver to finish ahead of his teammate when accounting for the handicap</li>
          </ul>
          <p>Example of the handicap: if a driver is listed as -3.5 it means we should move him back 3.5 spots and then compare with the teammate. +3.5 means the driver will move forward 3.5 spots. Handcaps will always end in .5 to avoid ties.</p>
        `
      },
      {
        title: "Scoring",
        content: `
          <ul>
            <li>Each correct prediction for the winner and the winner against the spread will result in 1 point for the player.</li>
            <li>For one team each round, the user can activate the joker card (🃏) which will double the points for that team.</li>
          </ul>
          <p>For the purpose of scoring, the official classification is used. That means all drivers who complete at least 90% of the race will have a finishing position.</p>
          <p>Also, sprint races? Forget about them. I have.</p>
        `
      },
      {
        title: "Fair Play",
        content: `
          <p>Every user is expected to behave honorably and with the highest moral character inside and outside the game during the tournament. Failure to do so will result in disqualification from the game.</p>
        `
      },
      {
        title: "Emojis",
        content: `
          <p>You may see all sorts of emojis during the season.</p>
          <ul>
            <li>🃏 - Played the joker card for the matchup</li>
            <li>🎯 - Predicted everything in the matchup correctly</li>
            <li>💎 - Predicted everything correctly in a joker card matchup</li>
            <li>💤 - Received 0 points for the matchup</li>
            <li>💩 - Received 0 points for a joker card matchup</li>
            <li>🦄 - Made a unique prediction</li>
            <li>🍻 - Made a popular prediction</li>
            <li>🤷 - Made a logically inconsistent prediction</li>
            <li>🤯 - Only user to receive points for the matchup</li>
            <li>😟 - Only user to not receive points for the matchup</li>
            <li>🔥 - On fire!</li>
            <li>🎖️ - Won most points for a race</li>
          </ul>
        `
      }
    ]
  }
};
function Rules({ tournamentId }) {
  const tournament = ruleSets[tournamentId] || ruleSets.default;

  return (
    <RulesContainer>
      <h1>{tournament.title}</h1>
      {tournament.rules.map((rule, index) => (
        <div key={index} className="rule-section">
          <h2>{`${index + 1}. ${rule.title}`}</h2>
          <div dangerouslySetInnerHTML={{ __html: rule.content }} />
        </div>
      ))}
    </RulesContainer>
  );
}

export default Rules;