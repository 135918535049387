import React, { useState, useEffect, useCallback } from "react";
import RoundsCarousel from "./components/RoundsCarousel";
import CheckIcon from "@mui/icons-material/Check";
import AddIcon from "@mui/icons-material/Add";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { Switch, FormControlLabel } from "@mui/material";
import "./styles/PlayerPicker.css";

function formatGameTime(dateTimeString) {
  const gameDate = new Date(dateTimeString);
  const etDate = new Date(gameDate.getTime() - 5 * 60 * 60 * 1000); // Adjust for ET (UTC-5)
  return etDate.toLocaleString(undefined, {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
    timeZoneName: "short",
  });
}

const formatGameInfo = (player) => {
  const game = dummyGames.find((g) => g.id === player.gameId);
  if (!game) return null;

  const gameTime = new Date(game.gameDateTime);
  const now = new Date();

  let mainInfo, detailInfo;

  const formatMatchup = () => {
    return player.team === game.homeTeam
      ? `${game.homeTeam} vs ${game.awayTeam}`
      : `${game.awayTeam} @ ${game.homeTeam}`;
  };

  const formatScore = () => {
    return player.team === game.homeTeam
      ? `${game.homeScore} - ${game.awayScore}`
      : `${game.awayScore} - ${game.homeScore}`;
  };

  if (game.homeScore !== null && game.awayScore !== null) {
    mainInfo = formatMatchup();
    detailInfo = formatScore();
  } else if (now > gameTime) {
    mainInfo = formatMatchup();
    detailInfo =
      now - gameTime < 2 * 60 * 60 * 1000 ? "In progress" : "Awaiting result";
  } else {
    mainInfo = formatMatchup();
    detailInfo = formatGameTime(game.gameDateTime);
  }

  return (
    <>
      <div>{mainInfo}</div>
      <div className="player-details">{detailInfo}</div>
    </>
  );
};

const realPlayerNames = [
  "Giannis Antetokounmpo",
  "LeBron James",
  "Kevin Durant",
  "Stephen Curry",
  "Nikola Jokic",
  "Joel Embiid",
  "Luka Doncic",
  "Kawhi Leonard",
  "James Harden",
  "Anthony Davis",
  "Damian Lillard",
  "Jayson Tatum",
  "Jimmy Butler",
  "Kyrie Irving",
  "Shai Gilgeous-Alexander",
  "Devin Booker",
  "Trae Young",
  "Zion Williamson",
  "Bam Adebayo",
  "Domantas Sabonis",
  "Ja Morant",
  "Karl-Anthony Towns",
  "Donovan Mitchell",
  "Rudy Gobert",
  "Khris Middleton",
  "Jrue Holiday",
  "Pascal Siakam",
  "Brandon Ingram",
  "Zach LaVine",
  "DeMar DeRozan",
  "Jaylen Brown",
  "Jamal Murray",
  "De'Aaron Fox",
  "Jaren Jackson Jr.",
  "Tyrese Haliburton",
  "Cade Cunningham",
  "Evan Mobley",
  "Scottie Barnes",
  "LaMelo Ball",
  "Anthony Edwards",
  "Darius Garland",
  "Jalen Green",
  "Paolo Banchero",
  "Franz Wagner",
  "Alperen Sengun",
  "Tyrese Maxey",
  "Desmond Bane",
  "Mikal Bridges",
  "OG Anunoby",
  "Keldon Johnson",
  "Tyler Herro",
  "Jordan Poole",
  "Jalen Brunson",
  "Anfernee Simons",
  "Josh Giddey",
  "Chet Holmgren",
  "Victor Wembanyama",
  "Scoot Henderson",
  "Jabari Smith Jr.",
  "Keegan Murray",
];

const dummyGames = [
  {
    id: 1,
    gameDateTime: "2023-09-15T19:00:00Z",
    homeTeam: "LAL",
    awayTeam: "BOS",
    homeScore: 102,
    awayScore: 100,
  },
  {
    id: 2,
    gameDateTime: "2023-09-20T20:00:00Z",
    homeTeam: "GSW",
    awayTeam: "PHX",
    homeScore: 115,
    awayScore: 110,
  },
  {
    id: 3,
    gameDateTime: new Date(new Date().getTime() - 60 * 60 * 1000).toISOString(),
    homeTeam: "MIL",
    awayTeam: "BKN",
    homeScore: null,
    awayScore: null,
  },
  {
    id: 4,
    gameDateTime: new Date(
      new Date().getTime() + 360 * 60 * 1000
    ).toISOString(),
    homeTeam: "DEN",
    awayTeam: "PHI",
    homeScore: null,
    awayScore: null,
  },
  {
    id: 5,
    gameDateTime: "2024-01-10T18:30:00Z",
    homeTeam: "MIA",
    awayTeam: "TOR",
    homeScore: null,
    awayScore: null,
  },
  {
    id: 6,
    gameDateTime: "2024-02-15T20:30:00Z",
    homeTeam: "LAC",
    awayTeam: "DAL",
    homeScore: null,
    awayScore: null,
  },
  {
    id: 7,
    gameDateTime: "2024-03-22T19:45:00Z",
    homeTeam: "CHI",
    awayTeam: "NYK",
    homeScore: null,
    awayScore: null,
  },
];

const generatePlayerStats = () => ({
  fpts: +(Math.random() * 40 + 10).toFixed(1),
  gp: Math.floor(Math.random() * 50 + 30),
  min: +(Math.random() * 20 + 15).toFixed(1),
  pts: +(Math.random() * 20 + 5).toFixed(1),
  reb: +(Math.random() * 10 + 2).toFixed(1),
  ast: +(Math.random() * 7 + 1).toFixed(1),
  stl: +(Math.random() * 2 + 0.5).toFixed(1),
  blk: +(Math.random() * 1.5 + 0.2).toFixed(1),
  tov: +(Math.random() * 3 + 1).toFixed(1),
});

const generatePlayers = (teamName, gameId, startId) => {
  const positions = ["PG", "SG", "SF", "PF", "C"];
  return Array.from({ length: 15 }, (_, index) => ({
    id: startId + index,
    name: realPlayerNames[Math.floor(Math.random() * realPlayerNames.length)],
    team: teamName,
    position: positions[Math.floor(Math.random() * positions.length)],
    gameId: gameId,
    guaranteedGames: Math.floor(Math.random() * 5),
    pickedPercentage: Math.floor(Math.random() * 100),
    stats: {
      regularSeason: generatePlayerStats(),
      playoffs: generatePlayerStats(),
    },
  }));
};

const dummyPlayers = dummyGames.flatMap((game, index) => [
  ...generatePlayers(game.homeTeam, game.id, index * 30 + 1),
  ...generatePlayers(game.awayTeam, game.id, index * 30 + 16),
]);

const PlayerPicker = () => {
  const generateDates = () => {
    const uniqueDates = [
      ...new Set(dummyGames.map((game) => game.gameDateTime.split("T")[0])),
    ];
    return uniqueDates.sort((a, b) => new Date(a) - new Date(b));
  };

  const [dates] = useState(generateDates());
  const [selectedDate, setSelectedDate] = useState(dates[0]);
  const [picks, setPicks] = useState({});
  const [showIneligible, setShowIneligible] = useState(false);
  const [statsType, setStatsType] = useState("regularSeason");
  const [playerSearch, setPlayerSearch] = useState("");
  const [teamFilter, setTeamFilter] = useState("All");
  const [filteredPlayers, setFilteredPlayers] = useState([]);
  const [sortConfig, setSortConfig] = useState({
    key: "fpts",
    direction: "desc",
  });
  const hasGameStarted = useCallback((gameId) => {
    const game = dummyGames.find((g) => g.id === gameId);
    if (!game) return false;
    const gameTime = new Date(game.gameDateTime);
    const etGameTime = new Date(gameTime.getTime() - 5 * 60 * 60 * 1000); // Adjust for ET (UTC-5)
    return new Date() > etGameTime;
  }, []);
  const isPlayerPickDisabled = useCallback(
    (playerId, date) => {
      const currentTime = new Date();
      const player = dummyPlayers.find((p) => p.id === playerId);
      if (!player) {
        console.error(`Player with id ${playerId} not found`);
        return true;
      }
      const game = dummyGames.find((g) => g.id === player.gameId);
      if (!game) {
        console.error(`Game for player ${playerId} not found`);
        return true;
      }
      const gameTime = new Date(game.gameDateTime);
      if (currentTime >= gameTime) {
        return true;
      }
      const pickedPlayerForDate = picks[date];
      if (pickedPlayerForDate) {
        const pickedPlayer = dummyPlayers.find(
          (p) => p.id === pickedPlayerForDate.id
        );
        if (pickedPlayer) {
          const pickedGame = dummyGames.find(
            (g) => g.id === pickedPlayer.gameId
          );
          if (pickedGame) {
            const pickedGameTime = new Date(pickedGame.gameDateTime);
            if (currentTime >= pickedGameTime) {
              console.log(
                `Picked player's game for ${date} has started, disabling picks for this date`
              );
              return true;
            }
          }
        }
      }
      return false;
    },
    [picks]
  );
  useEffect(() => {
    const gamesForDay = dummyGames.filter((game) =>
      game.gameDateTime.startsWith(selectedDate)
    );
    const playersForDay = dummyPlayers.filter((player) =>
      gamesForDay.some((game) => game.id === player.gameId)
    );

    const pickedPlayerIds = Object.values(picks).map((player) => player.id);

    const filtered = playersForDay.filter(
      (player) =>
        (showIneligible ||
          !pickedPlayerIds.includes(player.id) ||
          picks[selectedDate]?.id === player.id) &&
        player.name.toLowerCase().includes(playerSearch.toLowerCase()) &&
        (teamFilter === "All" || player.team === teamFilter)
    );

    setFilteredPlayers(filtered);
  }, [selectedDate, picks, showIneligible, playerSearch, teamFilter]);

  useEffect(() => {
    setTeamFilter("All"); // Reset team filter when date changes
  }, [selectedDate]);

  const handlePlayerPick = (player) => {
    if (isPlayerPickDisabled(player.id, selectedDate)) {
      console.log(
        "Cannot pick player, game has already started or picked player's game has started"
      );
      return;
    }

    setPicks((prevPicks) => ({
      ...prevPicks,
      [selectedDate]: player,
    }));
  };

  const handleSort = (key) => {
    setSortConfig((prevConfig) => ({
      key,
      direction:
        prevConfig.key === key && prevConfig.direction === "desc"
          ? "asc"
          : "desc",
    }));
  };

  const renderFilterPanel = () => {
    const gamesForDay = dummyGames.filter((game) =>
      game.gameDateTime.startsWith(selectedDate)
    );
    const teamsForDay = [
      ...new Set(gamesForDay.flatMap((game) => [game.homeTeam, game.awayTeam])),
    ].sort();
    return (
      <Paper className="filter-panel">
        <TextField
          label="Player Search"
          variant="outlined"
          size="small"
          value={playerSearch}
          onChange={(e) => setPlayerSearch(e.target.value)}
          className="filter-dropdown"
        />
        <FormControl
          variant="outlined"
          size="small"
          className="filter-dropdown"
        >
          <InputLabel>Team</InputLabel>
          <Select
            value={teamFilter}
            onChange={(e) => setTeamFilter(e.target.value)}
            label="Team"
          >
            <MenuItem value="All">All Teams</MenuItem>
            {teamsForDay.map((team) => (
              <MenuItem key={team} value={team}>
                {team}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl
          variant="outlined"
          size="small"
          className="filter-dropdown"
        >
          <InputLabel>Stats Type</InputLabel>
          <Select
            value={statsType}
            onChange={(e) => setStatsType(e.target.value)}
            label="Stats Type"
          >
            <MenuItem value="regularSeason">Regular Season</MenuItem>
            <MenuItem value="playoffs">Playoffs</MenuItem>
          </Select>
        </FormControl>
        <FormControlLabel
          control={
            <Switch
              checked={showIneligible}
              onChange={(e) => setShowIneligible(e.target.checked)}
              color="primary"
            />
          }
          label="Show All Players"
        />
      </Paper>
    );
  };

  const renderPlayerStats = () => {
    const sortedPlayers = [...filteredPlayers].sort((a, b) => {
      const aValue = a.stats[statsType][sortConfig.key];
      const bValue = b.stats[statsType][sortConfig.key];
      if (aValue < bValue) return sortConfig.direction === "asc" ? -1 : 1;
      if (aValue > bValue) return sortConfig.direction === "asc" ? 1 : -1;
      return 0;
    });
    const columnConfig = [
      { key: "name", label: "Player", sortable: true },
      { key: "game", label: "Game", sortable: false },
      {
        key: "guaranteedGames",
        label: "GGR",
        sortable: true,
        tooltip: "Guaranteed Games Remaining",
        style: { textDecoration: "underline dotted", cursor: "help" },
      },
      {
        key: "pickedPercentage",
        label: "Picked %",
        sortable: true,
        tooltip: "Not counting current gameday",
        style: { textDecoration: "underline dotted", cursor: "help" },
      },
      { key: "fpts", label: "FPts", sortable: true },
      { key: "gp", label: "GP", sortable: true },
      { key: "min", label: "Min", sortable: true },
      { key: "pts", label: "Pts", sortable: true },
      { key: "reb", label: "Reb", sortable: true },
      { key: "ast", label: "Ast", sortable: true },
      { key: "stl", label: "Stl", sortable: true },
      { key: "blk", label: "Blk", sortable: true },
      { key: "tov", label: "Tov", sortable: true },
    ];
    return (
      <div>
        <div className="table-container">
          <TableContainer component={Paper}>
            {renderFilterPanel()}
            <Table aria-label="Player Stats" size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Select</TableCell>
                  {columnConfig.map((column) => (
                    <TableCell
                      key={column.key}
                      align={column.align || "left"}
                      className={`sort-header ${
                        sortConfig.key === column.key
                          ? sortConfig.direction
                          : ""
                      }`}
                      onClick={() => column.sortable && handleSort(column.key)}
                    >
                      {column.tooltip ? (
                        <Tooltip title={column.tooltip} arrow>
                          <span style={column.style}>{column.label}</span>
                        </Tooltip>
                      ) : (
                        column.label
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedPlayers.map((player) => (
                  <TableRow key={player.id}>
                    <TableCell className="compact-cell">
                      <button
                        onClick={() => handlePlayerPick(player)}
                        disabled={
                          isPlayerPickDisabled(player.id, selectedDate) ||
                          (picks[selectedDate]?.id !== player.id &&
                            Object.values(picks).some(
                              (p) => p.id === player.id
                            ))
                        }
                        className={`
    game-button
    ${picks[selectedDate]?.id === player.id ? "button-selected" : ""}
    ${
      isPlayerPickDisabled(player.id, selectedDate) ||
      (picks[selectedDate]?.id !== player.id &&
        Object.values(picks).some((p) => p.id === player.id))
        ? "disabled"
        : ""
    }
  `}
                        title={
                          picks[selectedDate]?.id === player.id
                            ? "Selected"
                            : isPlayerPickDisabled(player.id, selectedDate)
                            ? "Pick disabled: Game has started or picked player's game has started"
                            : "Select"
                        }
                      >
                        {picks[selectedDate]?.id === player.id ? (
                          <CheckIcon />
                        ) : (
                          <AddIcon />
                        )}
                      </button>
                    </TableCell>
                    <TableCell className="compact-cell" width="145px">
                      <div>
                        <strong>{player.name}</strong>
                      </div>
                      <div className="player-details">
                        ({player.team} - {player.position})
                      </div>
                    </TableCell>
                    <TableCell className="compact-cell" width="75px">
                      {formatGameInfo(player)}
                    </TableCell>
                    <TableCell className="compact-cell" align="center">
                      {player.guaranteedGames}
                    </TableCell>
                    <TableCell
                      className="compact-cell"
                      align="center"
                    >{`${player.pickedPercentage}%`}</TableCell>
                    {[
                      "fpts",
                      "gp",
                      "min",
                      "pts",
                      "reb",
                      "ast",
                      "stl",
                      "blk",
                      "tov",
                    ].map((stat) => (
                      <TableCell
                        className="compact-cell"
                        key={stat}
                        align="center"
                      >
                        {stat === "gp"
                          ? Math.round(player.stats[statsType][stat])
                          : player.stats[statsType][stat].toFixed(1)}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    );
  };
  return (
    <div className="predictions-container player-picker">
      <RoundsCarousel
        rounds={dates}
        currentRound={selectedDate}
        setCurrentRound={setSelectedDate}
      />
      {renderPlayerStats()}
    </div>
  );
};

export default PlayerPicker;
