import React, { useState, useEffect } from "react";

function Log() {
  const [logs, setLogs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    fetch(`${process.env.REACT_APP_API_URL}/api/logs`)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        // Convert object of objects to array
        const logsArray = Object.values(data);
        setLogs(logsArray);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching logs:", error);
        setError(error.message);
        setIsLoading(false);
      });
  }, []);

  const formatLogMessage = (log) => {
    const date = new Date(log.timestamp);
    const formattedDate = date.toLocaleString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false
    });

    let message = `${formattedDate}: ${log.user_name} (${log.user_id}) ${log.action}`;

    if (log.team_name) {
      message += ` for ${log.team_name}`;
    }

    if (log.game_id) {
      message += ` in game ${log.game_id}`;
    }

    if (log.tournament_id) {
      message += ` in tournament ${log.tournament_id}`;
    }

    if (log.additional_info) {
      message += ` (${log.additional_info})`;
    }

    return message;
  };

  if (isLoading) return <div>Loading logs...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div>
      {logs.length === 0 ? (
        <div>No logs found.</div>
      ) : (
        logs.map((log, index) => (
          <div
            key={log.id}
            style={{
              fontSize: '0.8em',
              textAlign: 'left',
              marginLeft: '20px',
              marginBottom: '5px',
              padding: '5px',
              backgroundColor: index % 2 === 0 ? '#f0f0f0' : 'white',
            }}
          >
            {formatLogMessage(log)}
          </div>
        ))
      )}
    </div>
  );
}

export default Log;