import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
} from "react";
import io from "socket.io-client";
import { AuthContext } from "./contexts/AuthContext";
import { Link } from "react-router-dom";
import { format, isToday, isYesterday, isThisWeek, isThisYear } from "date-fns";

const formatMessageDate = (timestamp) => {
  const date = new Date(timestamp);

  if (isToday(date)) {
    return format(date, "HH:mm");
  } else if (isYesterday(date)) {
    return `Yesterday, ${format(date, "HH:mm")}`;
  } else if (isThisWeek(date)) {
    return format(date, "EEEE, HH:mm");
  } else if (isThisYear(date)) {
    return format(date, "dd MMM, HH:mm");
  } else {
    return format(date, "dd MMM yy, HH:mm");
  }
};

const validTLDs = [
  "com",
  "net",
  "org",
  "edu",
  "gov",
  "io",
  "ai",
  "app",
  "dev",
  "ee",
  "basketball",
  "watch",
  "lv",
  "lt",
];

const enhancedLinkify = (text) => {
  const tldPattern = validTLDs.join("|");
  const urlRegex = new RegExp(`([\\w-]+\\.)+(?:${tldPattern})(\\/\\S*)?`, "gi");

  return text.split(urlRegex).map((part, i) =>
    i % 2 === 0 ? (
      part
    ) : (
      <a
        key={i}
        href={`https://${part}`}
        target="_blank"
        rel="noopener noreferrer"
        style={{ color: "blue", textDecoration: "underline" }}
      >
        {part}
      </a>
    )
  );
};

const Chat = ({ tournamentId }) => {
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const { user } = useContext(AuthContext);
  const messageListRef = useRef(null);
  const [isPageVisible, setIsPageVisible] = useState(true);
  const [socket, setSocket] = useState(null);

  const handleNewMessage = useCallback(
    (msg) => {
      if (msg.id && msg.user_name && msg.timestamp) {
        setMessages((prevMessages) => [...prevMessages, msg]);

        if (!isPageVisible) {
          // Handle notification logic here
        }
      }
    },
    [isPageVisible]
  );

  useEffect(() => {
    // Create socket connection when component mounts
    const newSocket = io("https://www.playmaker.ee", {
      path: "/socket.io",
      transports: ["websocket", "polling"],
    });

    newSocket.on("connect", () => {
      console.log(
        `[${new Date().toISOString()}] Socket connected successfully`
      );
      console.log(`Transport used: ${newSocket.io.engine.transport.name}`);
    });

    newSocket.on("connect_error", (error) => {
      console.error(
        `[${new Date().toISOString()}] Socket connection error:`,
        error
      );
    });

    newSocket.on("disconnect", (reason) => {
      console.log(
        `[${new Date().toISOString()}] Socket disconnected. Reason: ${reason}`
      );
    });

    newSocket.on("reconnect", (attemptNumber) => {
      console.log(
        `[${new Date().toISOString()}] Socket reconnected after ${attemptNumber} attempts`
      );
    });

    newSocket.on("reconnect_attempt", (attemptNumber) => {
      console.log(
        `[${new Date().toISOString()}] Socket reconnection attempt ${attemptNumber}`
      );
    });

    newSocket.on("reconnect_error", (error) => {
      console.error(
        `[${new Date().toISOString()}] Socket reconnection error:`,
        error
      );
    });

    newSocket.on("reconnect_failed", () => {
      console.error(`[${new Date().toISOString()}] Socket failed to reconnect`);
    });

    newSocket.io.on("error", (error) => {
      console.error(`[${new Date().toISOString()}] Socket.IO error:`, error);
    });

    newSocket.io.on("reconnect", (attempt) => {
      console.log(
        `[${new Date().toISOString()}] Socket.IO reconnected on attempt ${attempt}`
      );
    });

    setSocket(newSocket);

    // Fetch initial messages
    fetch(
      `${process.env.REACT_APP_API_URL}/api/messages?tournamentId=${tournamentId}`
    )
      .then((response) => response.json())
      .then((data) => setMessages(data))
      .catch((error) => console.error("Error fetching messages:", error));

    const handleVisibilityChange = () => {
      setIsPageVisible(!document.hidden);
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    // Clean up function
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      if (newSocket) {
        newSocket.disconnect();
      }
    };
  }, [tournamentId]);
  useEffect(() => {
    console.log(`[${new Date().toISOString()}] Chat component mounted`);
    return () => {
      console.log(`[${new Date().toISOString()}] Chat component unmounted`);
    };
  }, []);
  useEffect(() => {
    if (socket) {
      socket.on("chat message", handleNewMessage);
    }

    return () => {
      if (socket) {
        socket.off("chat message", handleNewMessage);
      }
    };
  }, [socket, handleNewMessage]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    if (messageListRef.current) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
    }
  };

  const sendMessage = (e) => {
    e.preventDefault();
    if (inputMessage.trim() && user && socket) {
      try {
        socket.emit(
          "chat message",
          {
            user_id: user.id,
            content: inputMessage,
            tournament_id: tournamentId,
          },
          (acknowledgement) => {
            if (acknowledgement) {
              // Handle successful message send
            } else {
              // Handle unsuccessful message send
            }
          }
        );
      } catch (error) {
        console.error("Error emitting message:", error);
      }
      setInputMessage("");
    } else {
      // Handle error (e.g., user not logged in or no socket connection)
    }
  };

  return (
    <div className="chat-container">
      <div ref={messageListRef} className="message-list">
        {messages.map((msg, index) => (
          <div key={index} className="message" style={{ marginBottom: "10px" }}>
            <span style={{ fontWeight: "bold" }}>
              <Link to={`/profile/${msg.user_id}`}>{msg.user_name}</Link>
            </span>
            <span
              style={{ color: "#888", marginLeft: "5px", fontSize: "0.8em" }}
            >
              ({formatMessageDate(msg.timestamp)})
            </span>
            :
            <span style={{ marginLeft: "5px" }}>
              {enhancedLinkify(msg.content)}
            </span>
          </div>
        ))}
      </div>
      <form onSubmit={sendMessage} className="message-form">
        <input
          type="text"
          value={inputMessage}
          onChange={(e) => setInputMessage(e.target.value)}
          className="message-input"
        />
        <button type="submit" className="send-button">
          Send
        </button>
      </form>
    </div>
  );
};

export default Chat;
