import React, { useCallback, useEffect, useState } from "react";
import JokerIcon from "./components/JokerIcon";
import RoundsCarousel from "./components/RoundsCarousel";
import { Link } from "react-router-dom";

function F1Predictions({ user, tournamentId, predictions, setPredictions }) {
  const [races, setRaces] = useState([]);
  const [rounds, setRounds] = useState([]);
  const [raceDetails, setRaceDetails] = useState(null);
  const [currentRound, setCurrentRound] = useState(null);

  const fetchRaces = useCallback(async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/f1/races?tournamentId=${tournamentId}`
      );
      if (!response.ok) throw new Error("Failed to fetch races");
      const data = await response.json();
      setRaces(data);
      const uniqueRounds = [...new Set(data.map((race) => race.round))];
      setRounds(uniqueRounds);
      if (!currentRound) {
        const now = new Date();
        const nextRace = data.find((race) => new Date(race.date) > now);
        setCurrentRound(
          nextRace ? nextRace.round : uniqueRounds[uniqueRounds.length - 1]
        );
      }
    } catch (error) {
      console.error("Error fetching races:", error);
    }
  }, [tournamentId, currentRound, setCurrentRound]);

  const fetchRaceDetails = useCallback(
    async (raceId) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/f1/races/${raceId}/?tournamentId=${tournamentId}`
        );
        if (!response.ok) throw new Error("Failed to fetch race details");
        const data = await response.json();
        setRaceDetails(data);
      } catch (error) {
        console.error("Error fetching race details:", error);
      }
    },
    [tournamentId]
  );

  useEffect(() => {
    fetchRaces();
  }, [fetchRaces]);

  useEffect(() => {
    if (races.length > 0 && currentRound) {
      const race = races.find((race) => race.round === currentRound);
      if (race) {
        fetchRaceDetails(race.id);
      }
    }
  }, [currentRound, races, fetchRaceDetails]);

  const fetchPredictions = useCallback(async () => {
    if (!user) return;
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/f1/predictions/${user.id}?tournamentId=${tournamentId}`
      );
      if (!response.ok) throw new Error("Failed to fetch predictions");
      const data = await response.json();
      const predictionMap = {};
      data.forEach((pred) => {
        predictionMap[`${pred.race_id}-${pred.team_name}`] = pred;
      });
      setPredictions(predictionMap);
    } catch (error) {
      console.error("Error fetching predictions:", error);
    }
  }, [user, tournamentId, setPredictions]);

  useEffect(() => {
    fetchPredictions();
  }, [fetchPredictions]);

  const handlePrediction = async (raceId, teamName, driverName, isHandicap) => {
    if (!user) return;
    const key = `${raceId}-${teamName}`;
    const currentPrediction = predictions[key] || {};
    const updatedPrediction = {
      ...currentPrediction,
      user_id: user.id,
      race_id: raceId,
      team_name: teamName,
      [isHandicap ? "handicap_prediction" : "winner_prediction"]: driverName,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/f1/predictions`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatedPrediction),
        }
      );
      if (!response.ok) throw new Error("Failed to save prediction");
      await fetchPredictions();
    } catch (error) {
      console.error("Error saving prediction:", error);
    }
  };

  const handleDoublePoints = async (raceId, team) => {
    if (!user) return;
    const key = `${raceId}-${team}`;
    const currentPrediction = predictions[key] || {};
    const isDoublePoints = !currentPrediction.is_double_points;

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/f1/predictions/double-points`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId: user.id,
            raceId: raceId,
            teamName: team,
            isDoublePoints: isDoublePoints,
          }),
        }
      );
      if (!response.ok) throw new Error("Failed to set double points");
      await fetchPredictions();
    } catch (error) {
      console.error("Error setting double points:", error);
    }
  };

  const formatF1Time = (dateTimeString) => {
    const date = new Date(dateTimeString);
    return date.toLocaleString(undefined, {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
      timeZoneName: "short",
    });
  };

  const getF1Countdown = (qualifyingDate, raceDate, isCompleted) => {
    const now = new Date();
    const qualifyingTime = new Date(qualifyingDate);
    const raceTime = new Date(raceDate);
    const twoHoursAfterRace = new Date(raceTime.getTime() + 2 * 60 * 60 * 1000);

    if (isCompleted) {
      return "Final results";
    } else if (now < qualifyingTime) {
      const distance = qualifyingTime - now;
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      return `Predictions close in ${days}d ${hours}h ${minutes}m`;
    } else if (now < raceTime) {
      return "Predictions closed";
    } else if (now < twoHoursAfterRace) {
      return "In progress";
    } else {
      return "Awaiting results";
    }
  };

  const formatHandicap = (handicap) => {
    return handicap > 0 ? `+${handicap}` : handicap.toString();
  };

  const renderRaces = () => {
    if (!raceDetails) return null;

    const isPredictionsClosed =
      new Date() >= new Date(raceDetails.qualifying_date);
    const isRaceCompleted = raceDetails.teams.some(
      (team) => team.driver1_position != null || team.driver2_position != null
    );

    const sortedTeams = [...raceDetails.teams].sort((a, b) =>
      a.team_name.localeCompare(b.team_name)
    );

    return (
      <>
        <div
          className="race-info"
          style={{ marginBottom: "20px", textAlign: "center" }}
        >
          <h2 style={{ fontFamily: "Formula1 Display Bold" }}>
            {raceDetails.gp_emoji}{" "}
            <Link to={`/race/${raceDetails.id}`}>{raceDetails.name}</Link>
          </h2>
          <p>Qualifying: {formatF1Time(raceDetails.qualifying_date)}</p>
          <p>Race: {formatF1Time(raceDetails.date)}</p>
          <p>
            {getF1Countdown(
              raceDetails.qualifying_date,
              raceDetails.date,
              isRaceCompleted
            )}
          </p>{" "}
        </div>
        {raceDetails.teams && raceDetails.teams.length > 0 ? (
          <div className="games-container">
            {sortedTeams.map((team) => {
              const teamPrediction =
                predictions[`${raceDetails.id}-${team.team_name}`];

              const comparePositions = (pos1, pos2, handicap = 0) => {
                if (pos1 === null && pos2 === null) return 0;
                if (pos1 === null) return 1;
                if (pos2 === null) return -1;
                return pos1 - (pos2 + handicap);
              };

              const isWinnerCorrect =
                isRaceCompleted &&
                ((teamPrediction?.winner_prediction === team.driver1 &&
                  comparePositions(
                    team.driver1_position,
                    team.driver2_position
                  ) < 0) ||
                  (teamPrediction?.winner_prediction === team.driver2 &&
                    comparePositions(
                      team.driver2_position,
                      team.driver1_position
                    ) < 0));

              const isHandicapCorrect =
                isRaceCompleted &&
                team.handicap != null &&
                ((teamPrediction?.handicap_prediction === team.driver1 &&
                  (team.driver2_position === null ||
                    comparePositions(
                      team.driver1_position,
                      team.driver2_position,
                      team.handicap
                    ) < 0)) ||
                  (teamPrediction?.handicap_prediction === team.driver2 &&
                    (team.driver1_position === null ||
                      comparePositions(
                        team.driver2_position,
                        team.driver1_position,
                        -team.handicap
                      ) < 0)));

              return (
                <div
                  key={`${raceDetails.id}-${team.team_name}`}
                  className={`game-card ${
                    teamPrediction?.is_double_points
                      ? "double-points-game"
                      : "single-game"
                  }`}
                >
                  {/* Team header */}
                  <div
                    style={{
                      fontFamily: "Formula1 Display Bold",
                      backgroundColor: team.team_color || "#000000",
                      color: "white",
                      padding: "10px",
                      borderRadius: "4px 4px 0px 0px",
                    }}
                  >
                    <h3 style={{ margin: 0 }}>
                      {team.team_emoji} {team.team_name}
                    </h3>
                  </div>
                  {/* Winner prediction */}
                  <div style={{ marginTop: "10px" }}>
                    <button
                      disabled={isPredictionsClosed}
                      onClick={() =>
                        handlePrediction(
                          raceDetails.id,
                          team.team_name,
                          team.driver1,
                          false
                        )
                      }
                      className={`
                        ${
                          teamPrediction?.winner_prediction === team.driver1
                            ? "button-selected"
                            : ""
                        }
                        ${
                          isRaceCompleted &&
                          teamPrediction?.winner_prediction === team.driver1
                            ? isWinnerCorrect
                              ? "correct-prediction"
                              : "incorrect-prediction"
                            : ""
                        }
                      `}
                      style={
                        teamPrediction?.winner_prediction === team.driver1
                          ? {
                              backgroundColor: team.team_color,
                              color: "white",
                              fontFamily: "Formula1 Display Regular",
                              fontSize: 11,
                            }
                          : {
                              fontFamily: "Formula1 Display Regular",
                              fontSize: 11,
                            }
                      }
                    >
                      {team.driver1_flag} {team.driver1}
                    </button>
                    <button
                      disabled={isPredictionsClosed}
                      onClick={() =>
                        handlePrediction(
                          raceDetails.id,
                          team.team_name,
                          team.driver2,
                          false
                        )
                      }
                      className={`
                        ${
                          teamPrediction?.winner_prediction === team.driver2
                            ? "button-selected"
                            : ""
                        }
                        ${
                          isRaceCompleted &&
                          teamPrediction?.winner_prediction === team.driver2
                            ? isWinnerCorrect
                              ? "correct-prediction"
                              : "incorrect-prediction"
                            : ""
                        }
                      `}
                      style={
                        teamPrediction?.winner_prediction === team.driver2
                          ? {
                              backgroundColor: team.team_color,
                              color: "white",
                              fontFamily: "Formula1 Display Regular",
                              fontSize: 11,
                            }
                          : {
                              fontFamily: "Formula1 Display Regular",
                              fontSize: 11,
                            }
                      }
                    >
                      {team.driver2_flag} {team.driver2}
                    </button>
                  </div>
                  {/* Handicap prediction */}
                  {team.handicap != null ? (
                    <div style={{ marginTop: "10px" }}>
                      <button
                        disabled={isPredictionsClosed}
                        onClick={() =>
                          handlePrediction(
                            raceDetails.id,
                            team.team_name,
                            team.driver1,
                            true
                          )
                        }
                        className={`
                          ${
                            teamPrediction?.handicap_prediction === team.driver1
                              ? "button-selected"
                              : ""
                          }
                          ${
                            isRaceCompleted &&
                            teamPrediction?.handicap_prediction === team.driver1
                              ? isHandicapCorrect
                                ? "correct-prediction"
                                : "incorrect-prediction"
                              : ""
                          }
                        `}
                        style={
                          teamPrediction?.handicap_prediction === team.driver1
                            ? {
                                backgroundColor: team.team_color,
                                color: "white",
                                fontFamily: "Formula1 Display Regular",
                                fontSize: 11,
                              }
                            : {
                                fontFamily: "Formula1 Display Regular",
                                fontSize: 11,
                              }
                        }
                      >
                        {team.driver1} ({formatHandicap(team.handicap)})
                      </button>
                      <button
                        disabled={isPredictionsClosed}
                        onClick={() =>
                          handlePrediction(
                            raceDetails.id,
                            team.team_name,
                            team.driver2,
                            true
                          )
                        }
                        className={`
                          ${
                            teamPrediction?.handicap_prediction === team.driver2
                              ? "button-selected"
                              : ""
                          }
                          ${
                            isRaceCompleted &&
                            teamPrediction?.handicap_prediction === team.driver2
                              ? isHandicapCorrect
                                ? "correct-prediction"
                                : "incorrect-prediction"
                              : ""
                          }
                        `}
                        style={
                          teamPrediction?.handicap_prediction === team.driver2
                            ? {
                                backgroundColor: team.team_color,
                                color: "white",
                                fontFamily: "Formula1 Display Regular",
                                fontSize: 11,
                              }
                            : {
                                fontFamily: "Formula1 Display Regular",
                                fontSize: 11,
                              }
                        }
                      >
                        {team.driver2} ({formatHandicap(-team.handicap)})
                      </button>
                    </div>
                  ) : (
                    <div style={{ marginTop: "10px", fontStyle: "italic" }}>
                      Handicap not yet available
                    </div>
                  )}
                  {/* Double points selection */}
                  <div style={{ marginTop: "10px" }}>
                    <JokerIcon
                      isActive={teamPrediction?.is_double_points}
                      isButton
                      isDisabled={isPredictionsClosed}
                      onClick={() =>
                        handleDoublePoints(raceDetails.id, team.team_name)
                      }
                    />
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div style={{ textAlign: "center", marginTop: "20px" }}>
            <p>Team information is not yet available for this race.</p>
          </div>
        )}
      </>
    );
  };
  return (
    <div className="predictions-container">
      <RoundsCarousel
        rounds={rounds}
        currentRound={currentRound}
        setCurrentRound={setCurrentRound}
      />
      {renderRaces()}
    </div>
  );
}

export default React.memo(F1Predictions);
