import React, { useState, useEffect, useContext, useCallback } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Link,
} from "react-router-dom";
import "./styles/App.css";
import GameDetails from "./GameDetails";
import UserProfile from "./UserProfile";
import Leaderboard from "./Leaderboard";
import Chat from "./Chat";
import { AuthContext } from "./contexts/AuthContext";
import Predictions from "./Predictions";
import F1Predictions from "./F1Predictions";
import NavBar from "./components/NavBar";
import Rules from "./Rules";
import Footer from "./components/Footer";
import RaceDetails from "./RaceDetails";
import PlayerPicker from "./PlayerPicker";
import Log from "./Log";
export {
  formatGameTime,
  formatGameName,
  getCountdown,
  getUTC2Date,
  renderSpreadCell,
  renderF1SpreadCell,
};

console.log(process.env.REACT_APP_API_URL);

function renderSpreadCell(game, prediction) {
  if (!prediction.spread_prediction) {
    return "";
  }
  if (prediction.spread_prediction === prediction.team1) {
    return game.spread > 0
      ? `${prediction.spread_prediction} (+${game.spread})`
      : `${prediction.spread_prediction} (${game.spread})`;
  } else if (prediction.spread_prediction === prediction.team2) {
    return game.spread > 0
      ? `${prediction.spread_prediction} (-${game.spread})`
      : `${prediction.spread_prediction} (+${Math.abs(game.spread)})`;
  }

  return "";
}
function renderF1SpreadCell(game, prediction) {
  if (!prediction.handicap_prediction) {
    return "";
  }
  if (prediction.handicap_prediction === prediction.driver1) {
    return game.spread > 0
      ? `${prediction.handicap_prediction} (+${game.spread})`
      : `${prediction.handicap_prediction} (${game.spread})`;
  } else if (prediction.handicap_prediction === prediction.driver2) {
    return game.spread > 0
      ? `${prediction.handicap_prediction} (-${game.spread})`
      : `${prediction.handicap_prediction} (+${Math.abs(game.spread)})`;
  }

  return "";
}

function formatGameName(game) {
  const gameName =
    game.score1 !== null && game.score2 !== null
      ? `${game.team1} ${game.emoji1} ${game.score1}-${game.score2} ${game.emoji2} ${game.team2}`
      : `${game.team1} ${game.emoji1} vs. ${game.emoji2} ${game.team2}`;

  return (
    <b>
      <Link to={`/game/${game.id}`}>{gameName}</Link>
    </b>
  );
}

function formatGameTime(dateTimeString) {
  const gameDate = new Date(dateTimeString);

  const utcDate = new Date(gameDate.getTime() - 2 * 60 * 60 * 1000);

  return utcDate.toLocaleString(undefined, {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
    timeZoneName: "short",
  });
}

function getUTC2Date() {
  const now = new Date();
  return new Date(
    Date.UTC(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate(),
      now.getUTCHours() + 2,
      now.getUTCMinutes(),
      now.getUTCSeconds()
    )
  );
}

function getCountdown(targetDate) {
  const now = getUTC2Date();
  const gameTime = new Date(targetDate);
  const distance = gameTime - now;

  if (distance < -2 * 60 * 60 * 1000) {
    return "Awaiting result";
  } else if (distance < 0) {
    return "Game in progress";
  } else {
    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    return `(In ${days}d ${hours}h ${minutes}m)`;
  }
}

function App() {
  const [predictions, setPredictions] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const { user, login, logout } = useContext(AuthContext);
  const [tournamentId, setTournamentId] = useState(null);
  const [tournaments, setTournaments] = useState([]);

  const initializeTournament = useCallback((availableTournaments) => {
    const storedId = localStorage.getItem("tournamentId");
    if (
      storedId &&
      availableTournaments.some((t) => t.id.toString() === storedId)
    ) {
      return parseInt(storedId, 10);
    }
    return availableTournaments[0]?.id || null;
  }, []);

  useEffect(() => {
    const fetchAndInitializeTournaments = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/tournaments`
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        const visibleTournaments = data.filter(
          (tournament) => tournament.visible
        );
        setTournaments(visibleTournaments);

        if (visibleTournaments.length > 0) {
          const initialTournamentId = initializeTournament(visibleTournaments);
          setTournamentId(initialTournamentId);
          localStorage.setItem("tournamentId", initialTournamentId.toString());
        } else {
          console.error("No visible tournaments");
        }
      } catch (error) {
        console.error("Error fetching tournaments:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAndInitializeTournaments();
  }, [initializeTournament]);

  const handleTournamentChange = useCallback((newTournamentId) => {
    setTournamentId(newTournamentId);
    localStorage.setItem("tournamentId", newTournamentId.toString());
  }, []);

  const isF1Tournament = useCallback((tournament) => {
    return (
      tournament?.sport === "f1" ||
      tournament?.name.toLowerCase().includes("f1")
    );
  }, []);

  if (isLoading || !tournamentId) {
    return <div>Loading...</div>;
  }

  const currentTournament = tournaments.find((t) => t.id === tournamentId);

  return (
    <Router>
      <div className="App">
        {!isLoading && tournamentId && (
          <NavBar
            user={user}
            login={login}
            logout={logout}
            tournamentId={tournamentId}
            setTournamentId={handleTournamentChange}
            tournaments={tournaments}
          />
        )}
        <Routes>
          <Route
            path="/"
            element={
              user ? (
                currentTournament?.is_over ? (
                  <Navigate to="/leaderboard" replace />
                ) : (
                  <Navigate to="/predictions" replace />
                )
              ) : (
                <Navigate to="/leaderboard" replace />
              )
            }
          />
          <Route
            path="/predictions"
            element={
              user ? (
                isF1Tournament(currentTournament) ? (
                  <F1Predictions
                    user={user}
                    tournamentId={tournamentId}
                    predictions={predictions}
                    setPredictions={setPredictions}
                  />
                ) : (
                  <Predictions
                    user={user}
                    predictions={predictions}
                    setPredictions={setPredictions}
                    tournamentId={tournamentId}
                  />
                )
              ) : (
                <Navigate to="/leaderboard" replace />
              )
            }
          />
          <Route
            path="/leaderboard"
            element={(() => {
              return (
                <Leaderboard
                  user={user}
                  tournamentId={tournamentId}
                  tournamentOver={currentTournament?.is_over}
                  isF1Tournament={isF1Tournament(currentTournament)}
                />
              );
            })()}
          />
          <Route
            path="/rules"
            element={<Rules tournamentId={tournamentId} />}
          />
          <Route
            path="/chat"
            element={
              user ? (
                <Chat user={user} tournamentId={tournamentId} />
              ) : (
                <Navigate to="/leaderboard" replace />
              )
            }
          />
          <Route
            path="/profile"
            element={
              user ? (
                <Navigate to={`/profile/${user.id}`} replace />
              ) : (
                <Navigate to="/leaderboard" replace />
              )
            }
          />
          <Route
            path="/profile/:userId"
            element={
              user ? (
                <UserProfile user={user} tournamentId={tournamentId} />
              ) : (
                <Navigate to="/leaderboard" replace />
              )
            }
          />
          <Route
            path="/game/:id"
            element={<GameDetails user={user} tournamentId={tournamentId} />}
          />
          <Route
            path="/race/:id"
            element={<RaceDetails user={user} tournamentId={tournamentId} />}
          />
          <Route
            path="/log"
            element={
              user && [1, 2, 3].includes(user.id) ? (
                <Log />
              ) : (
                <Navigate to="/leaderboard" replace />
              )
            }
          />
          <Route path="/playerpicker" element={<PlayerPicker />} />{" "}
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
