import React, { useState, useEffect, useCallback, useRef } from "react";
import JokerIcon from "./components/JokerIcon";
import { formatGameTime, formatGameName, getUTC2Date, getCountdown } from "./App";
import RoundsCarousel from "./components/RoundsCarousel";

function Predictions({
  user,
  predictions,
  setPredictions,
  tournamentId,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [games, setGames] = useState([]);
  const [rounds, setRounds] = useState([]);
  const [currentRound, setCurrentRound] = useState(null);
  const [countdowns, setCountdowns] = useState({});
  const [error, setError] = useState(null);
  const [openDropdowns, setOpenDropdowns] = useState({});
  const [shuffledPlayers, setShuffledPlayers] = useState({});
  const dropdownRef = useRef({});

  useEffect(() => {
    const fetchGames = async () => {
      if (user && user.id && tournamentId) {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/api/games?tournamentId=${tournamentId}`
          );
          if (!response.ok) throw new Error("Failed to fetch games");
          const gamesData = await response.json();

          const parsedGames = gamesData.map((game) => ({
            ...game,
            spread: game.spread !== null ? parseFloat(game.spread) : null,
            score1: game.score1 !== null ? parseInt(game.score1, 10) : null,
            score2: game.score2 !== null ? parseInt(game.score2, 10) : null,
          }));

          const sortedGames = parsedGames.sort(
            (a, b) => new Date(a.date) - new Date(b.date)
          );

          setGames(sortedGames);

          const uniqueRounds = [
            ...new Set(sortedGames.map((game) => game.round)),
          ];
          setRounds(uniqueRounds);

          const now = getUTC2Date();
          const nextGame = sortedGames.find(
            (game) => new Date(game.date) > now
          );
          const nextRound = nextGame
            ? nextGame.round
            : uniqueRounds[uniqueRounds.length - 1];

          setCurrentRound(nextRound);
        } catch (error) {
          console.error("Failed to load games:", error);
        }
      }
    };
    fetchGames();
  }, [user, tournamentId]);

  useEffect(() => {
    const updateCountdowns = () => {
      setCountdowns((prevCountdowns) => {
        const newCountdowns = {};
        games.forEach((game) => {
          const countdown = getCountdown(game.date);
          if (countdown !== prevCountdowns[game.id]) {
            newCountdowns[game.id] = countdown;
          }
        });
        return { ...prevCountdowns, ...newCountdowns };
      });
    };

    updateCountdowns();
    const interval = setInterval(updateCountdowns, 60000);
    return () => clearInterval(interval);
  }, [games]);

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));

      [array[i], array[j]] = [array[j], array[i]];
    }

    return array;
  };

  useEffect(() => {
    const newShuffledPlayers = {};
    games.forEach((game) => {
      newShuffledPlayers[game.id] = shuffleArray([...game.players]);
    });
    setShuffledPlayers(newShuffledPlayers);
  }, [games]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      Object.keys(openDropdowns).forEach((gameId) => {
        if (
          dropdownRef.current[gameId] &&
          !dropdownRef.current[gameId].contains(event.target)
        ) {
          setOpenDropdowns((prev) => ({ ...prev, [gameId]: false }));
        }
      });
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openDropdowns]);

  const toggleDropdown = (gameId) => {
    setOpenDropdowns((prev) => ({ ...prev, [gameId]: !prev[gameId] }));
  };

  const handleError = useCallback((message) => {
    console.error(message);

    setError(message);
  }, []);

  const fetchPredictions = useCallback(async () => {
    if (!user) return;

    setIsLoading(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/user/${user.id}/old?tournamentId=${tournamentId}`
      );

      if (!response.ok) throw new Error("Failed to fetch predictions");

      const userData = await response.json();

      const formattedPredictions = {};

      userData.predictions.forEach((pred) => {
        formattedPredictions[pred.game_id] = {
          straight: pred.straight_prediction,

          spread: pred.spread_prediction,

          topScorer: pred.top_scorer_prediction,

          isDoublePoints: pred.is_double_points,
        };
      });

      setPredictions(formattedPredictions);
    } catch (error) {
      console.error("Error in fetchPredictions:", error);

      handleError("Failed to fetch predictions");
    } finally {
      setIsLoading(false);
    }
  }, [user, setPredictions, tournamentId, handleError]);

  useEffect(() => {
    if (user && user.id) {
      fetchPredictions();
    }
  }, [user, fetchPredictions]);

  const handlePrediction = async (game_id, prediction, againstSpread) => {
    if (!user) {
      handleError("You must be logged in to make predictions.");

      return;
    }

    setIsLoading(true);

    setError(null);

    try {
      const game = games.find((g) => g.id === game_id);

      if (!game) throw new Error("Invalid game selected");

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/predictions`,
        {
          method: "POST",

          headers: {
            "Content-Type": "application/json",

            Authorization: `Bearer ${user.token}`,
          },

          body: JSON.stringify({
            user_id: user.id,

            game_id: game_id,

            straight_prediction: againstSpread ? null : prediction,

            spread_prediction: againstSpread ? prediction : null,

            is_double_points: predictions[game_id]?.isDoublePoints || false,

            team1: game.team1,

            team2: game.team2,

            emoji1: game.emoji1,

            emoji2: game.emoji2,
          }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();

        throw new Error(errorData.error || "Failed to save prediction");
      }

      await fetchPredictions();
    } catch (err) {
      handleError(`Error saving prediction: ${err.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleTopScorer = async (game_id, player) => {
    if (!user) {
      handleError("You must be logged in to make predictions.");

      return;
    }

    setIsLoading(true);

    setError(null);

    try {
      const game = games.find((g) => g.id === game_id);

      if (!game) throw new Error("Invalid game selected");

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/predictions`,
        {
          method: "POST",

          headers: {
            "Content-Type": "application/json",

            Authorization: `Bearer ${user.token}`,
          },

          body: JSON.stringify({
            user_id: user.id,

            game_id: game_id,

            top_scorer_prediction: player,

            is_double_points: predictions[game_id]?.isDoublePoints || false,

            team1: game.team1,

            team2: game.team2,

            emoji1: game.emoji1,

            emoji2: game.emoji2,
          }),
        }
      );

      if (!response.ok) throw new Error("Failed to save top scorer prediction");

      await fetchPredictions();
    } catch (err) {
      handleError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDoublePoints = async (game_id) => {
    if (!user) {
      handleError("You must be logged in to set double points.");

      return;
    }

    setIsLoading(true);

    setError(null);

    try {
      const game = games.find((g) => g.id === game_id);

      if (!game) {
        throw new Error("Game not found");
      }

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/predictions/double-points`,
        {
          method: "POST",

          headers: {
            "Content-Type": "application/json",

            Authorization: `Bearer ${user.token}`,
          },

          body: JSON.stringify({
            user_id: user.id,

            game_id: game_id,

            round: game.round,

            is_double_points: !predictions[game_id]?.isDoublePoints,

            team1: game.team1,

            team2: game.team2,

            emoji1: game.emoji1,

            emoji2: game.emoji2,
          }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();

        throw new Error(errorData.error || "Failed to set double points");
      }

      await fetchPredictions();
    } catch (err) {
      handleError(`Error in handleDoublePoints: ${err.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const isTopScorerCorrect = (prediction, actual) => {
    if (!prediction || !actual) return false;

    const actualScorers = actual.split(",").map((s) => s.trim());

    return actualScorers.includes(prediction);
  };

  const formatSpread = (team, spread) => {
    if (team === "team1") {
      return spread > 0 ? `(+${spread})` : `(${spread})`;
    } else {
      return spread > 0 ? `(${-spread})` : `(+${-spread})`;
    }
  };

  const isDoublePointsDisabled = useCallback(
    (gameId) => {
      const currentTime = getUTC2Date();

      const game = games.find((g) => g.id === gameId);

      if (!game) {
        console.error(`Game with id ${gameId} not found`);

        return true;
      }

      const gameTime = new Date(game.date);

      if (currentTime >= gameTime) {
        return true;
      }

      const doublePointsGameForRound = Object.entries(predictions).find(
        ([id, pred]) => {
          const predGame = games.find((g) => g.id === parseInt(id));

          return pred.isDoublePoints && predGame?.round === game.round;
        }
      );

      if (doublePointsGameForRound) {
        const [doublePointsGameId] = doublePointsGameForRound;

        const doublePointsGame = games.find(
          (g) => g.id === parseInt(doublePointsGameId)
        );

        if (doublePointsGame) {
          const doublePointsGameTime = new Date(doublePointsGame.date);

          if (currentTime >= doublePointsGameTime) {
            console.log(
              `Double points game ${doublePointsGameId} has started, disabling for all games in round ${game.round}`
            );

            return true;
          }
        }
      }

      return false;
    },
    [games, predictions]
  );

  const renderGames = () => {
    return (
      <div className="games-container">
        {games
          .filter((game) => game.round === currentRound)
          .map((game) => {
            const gameTime = new Date(game.date);
            const isGameStarted = getUTC2Date() >= gameTime;
            const isDoublePoints = predictions[game.id]?.isDoublePoints;

            return (
              <div
                key={game.id}
                className={`game-card ${
                  isDoublePoints ? "double-points-game" : "single-game"
                }`}
              >
                <div className="game-card-head">
                  <p>{formatGameName(game)}</p>
                  {formatGameTime(game.date)}
                  <br />
                  {game.score1 ? "Final result" : countdowns[game.id]}
                </div>
                {/* Straight prediction */}
                <div style={{ marginTop: "10px" }}>
                  <button
                    disabled={isGameStarted}
                    onClick={() => handlePrediction(game.id, game.team1, false)}
                    className={`
                      ${
                        predictions[game.id]?.straight === game.team1
                          ? "button-selected"
                          : ""
                      }
                      ${
                        game.score1 > game.score2 &&
                        predictions[game.id]?.straight === game.team1
                          ? "correct-prediction"
                          : ""
                      }
                      ${
                        game.score1 < game.score2 &&
                        predictions[game.id]?.straight === game.team1
                          ? "incorrect-prediction"
                          : ""
                      }
                    `}
                  >
                    {game.team1}
                  </button>
                  <button
                    disabled={isGameStarted}
                    onClick={() => handlePrediction(game.id, game.team2, false)}
                    className={`
                      ${
                        predictions[game.id]?.straight === game.team2
                          ? "button-selected"
                          : ""
                      }
                      ${
                        game.score1 < game.score2 &&
                        predictions[game.id]?.straight === game.team2
                          ? "correct-prediction"
                          : ""
                      }
                      ${
                        game.score1 > game.score2 &&
                        predictions[game.id]?.straight === game.team2
                          ? "incorrect-prediction"
                          : ""
                      }
                    `}
                  >
                    {game.team2}
                  </button>
                </div>
                {/* Spread prediction */}
                {game.spread != null ? (
                  <div style={{ marginTop: "10px" }}>
                    {(() => {
                      const team1Predicted =
                        predictions[game.id]?.spread === game.team1;
                      const team2Predicted =
                        predictions[game.id]?.spread === game.team2;
                      const gameFinished =
                        game.score1 != null && game.score2 != null;
                      const spread = parseFloat(game.spread);
                      const team1CoveredSpread =
                        gameFinished && game.score1 + spread > game.score2;
                      const team2CoveredSpread =
                        gameFinished && game.score1 + spread < game.score2;
                      const getButtonClass = (
                        teamPredicted,
                        teamCoveredSpread
                      ) => {
                        if (!gameFinished)
                          return teamPredicted ? "button-selected" : "";
                        if (teamPredicted && teamCoveredSpread)
                          return "button-selected correct-prediction";
                        if (teamPredicted && !teamCoveredSpread)
                          return "button-selected incorrect-prediction";
                        return "";
                      };

                      const team1Class = getButtonClass(
                        team1Predicted,
                        team1CoveredSpread,
                        game.id,
                        game.team1
                      );
                      const team2Class = getButtonClass(
                        team2Predicted,
                        team2CoveredSpread,
                        game.id,
                        game.team2
                      );

                      return (
                        <>
                          <button
                            disabled={isGameStarted}
                            onClick={() =>
                              handlePrediction(game.id, game.team1, true)
                            }
                            className={team1Class}
                          >
                            {game.team1} {formatSpread("team1", game.spread)}
                          </button>
                          <button
                            disabled={isGameStarted}
                            onClick={() =>
                              handlePrediction(game.id, game.team2, true)
                            }
                            className={team2Class}
                          >
                            {game.team2} {formatSpread("team2", game.spread)}
                          </button>
                        </>
                      );
                    })()}
                  </div>
                ) : (
                  <div style={{ marginTop: "10px", fontStyle: "italic" }}>
                    Spread not yet available
                  </div>
                )}
                {/* Top scorer prediction */}
                <div
                  className="top-scorer-container"
                  ref={(el) => (dropdownRef.current[game.id] = el)}
                >
                  {" "}
                  Top Scorer:{" "}
                  <button
                    onClick={() => toggleDropdown(game.id)}
                    disabled={isGameStarted}
                    className={`
                      top-scorer-button
                      ${
                        predictions[game.id]?.topScorer ? "button-selected" : ""
                      }
                      ${
                        game.top_scorer &&
                        isTopScorerCorrect(
                          predictions[game.id]?.topScorer,
                          game.top_scorer
                        )
                          ? "correct-prediction"
                          : ""
                      }
                      ${
                        game.top_scorer &&
                        predictions[game.id]?.topScorer &&
                        !isTopScorerCorrect(
                          predictions[game.id]?.topScorer,
                          game.top_scorer
                        )
                          ? "incorrect-prediction"
                          : ""
                      }
                      ${isGameStarted ? "disabled" : ""}
                    `}
                  >
                    {predictions[game.id]?.topScorer || "Select player"}
                  </button>
                  {openDropdowns[game.id] && (
                    <div className="top-scorer-dropdown">
                      <button
                        onClick={() => {
                          handleTopScorer(game.id, "");
                          toggleDropdown(game.id);
                        }}
                      >
                        Select player
                      </button>
                      {shuffledPlayers[game.id] &&
                        shuffledPlayers[game.id].map((player) => (
                          <button
                            key={player}
                            onClick={() => {
                              handleTopScorer(game.id, player);
                              toggleDropdown(game.id);
                            }}
                          >
                            {player}
                          </button>
                        ))}
                    </div>
                  )}
                </div>{" "}
                {/* Double points selection */}
                <div style={{ marginTop: "10px" }}>
                  <JokerIcon
                    isActive={isDoublePoints}
                    isButton
                    isDisabled={isDoublePointsDisabled(game.id)}
                    onClick={() => handleDoublePoints(game.id)}
                  />
                </div>
              </div>
            );
          })}
      </div>
    );
  };

  return (
    <div className="predictions-container">
      <RoundsCarousel
        rounds={rounds}
        currentRound={currentRound}
        setCurrentRound={setCurrentRound}
      />
      {currentRound && renderGames()}
      {isLoading && <p>Loading...</p>}
      {error && <p>Error: {error}</p>}
    </div>
  );
}

export default Predictions;
