import React, { useState, useRef, useEffect, useContext } from "react";
import { NavLink, Link, useNavigate, useLocation } from "react-router-dom";
import "../styles/NavBar.css";
import { GoogleLogin, googleLogout } from "@react-oauth/google";
import { AuthContext } from "../contexts/AuthContext";

const NavBar = ({ user, tournamentId, setTournamentId, tournaments }) => {
  const { login, logout } = useContext(AuthContext);
  const [isOpen, setIsOpen] = useState(false);
  const [isTournamentDropdownOpen, setIsTournamentDropdownOpen] =
    useState(false);
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);
  const toggleMenu = () => setIsOpen(!isOpen);
  const getSportEmoji = (sport) => {
    if (!sport) return { emoji: "🏆", name: "Tournament" };

    switch (sport.toLowerCase()) {
      case "f1":
        return { emoji: "🏎️", name: "F1" };
      case "basketball":
        return { emoji: "🏀", name: "Basketball" };
      default:
        return { emoji: "🏆", name: sport };
    }
  };

  const mainMenuItems = [
    {
      label: "Predictions",

      path: "/predictions",

      icon: "🔮",

      authRequired: true,
    },

    {
      label: "Leaderboard",

      path: "/leaderboard",

      icon: "🏆",

      authRequired: false,
    },

    { label: "Trash Talk", path: "/chat", icon: "🗣️", authRequired: true },
  ];

  const secondaryMenuItems = [
    { label: "Profile", path: "/profile", icon: "👤", authRequired: true },

    { label: "Rules", path: "/rules", icon: "📜", authRequired: false },
  ];

  const navigate = useNavigate();

  const location = useLocation();

  const renderMenuItems = (items) =>
    items.map(
      (item) =>
        (!item.authRequired || user) && (
          <NavLink
            key={item.path}
            to={
              item.path === "/profile" && user
                ? `/profile/${user.id}`
                : item.path
            }
            className={({ isActive }) => (isActive ? "active" : "")}
            onClick={toggleMenu}
          >
            <span className="icon">{item.icon}</span>
            <span className="label">{item.label}</span>{" "}
          </NavLink>
        )
    );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        setIsTournamentDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleTournamentSelection = (id) => {
    console.log("Selected tournament ID:", id);

    setTournamentId(id);

    setIsTournamentDropdownOpen(false);

    setIsOpen(false);

    const currentPath = location.pathname;

    if (currentPath.startsWith("/game/")) {
      navigate(`/predictions`);
    }
  };

  const toggleDropdown = (e) => {
    e.stopPropagation();

    setIsTournamentDropdownOpen((prev) => !prev);
  };

  const handleLogin = (credentialResponse) => {
    console.log("Login button clicked");
    try {
      login(credentialResponse);
    } catch (error) {
      console.error("Login error:", error);
    }
    toggleMenu();
  };

  const handleLogout = () => {
    googleLogout();
    logout();
    toggleMenu();
  };

  const [isMobileDropdownOpen, setIsMobileDropdownOpen] = useState(false);

  const handleMobileTournamentSelection = (id) => {
    setTournamentId(id);

    setIsMobileDropdownOpen(false);
  };

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <div className="navbar-brand">
          <Link to="/" className="navbar-brand">
            <img src="/logo512.png" alt="Logo" className="navbar-logo" />

            <span className="site-name">playmaker.ee</span>
          </Link>
        </div>

        <div className="navbar-links main-menu">
          <div className="tournament-dropdown">
            <button
              ref={buttonRef}
              onClick={toggleDropdown}
              className="tournament-button"
            >
              <span className="icon">
                {
                  getSportEmoji(
                    tournaments.find((t) => t.id === tournamentId)?.sport
                  ).emoji
                }
              </span>
              <span>
                {tournaments.find((t) => t.id === tournamentId)
                  ? tournaments.find((t) => t.id === tournamentId).name
                  : "Select Tournament"}
              </span>
              <span style={{ marginLeft: "5px", fontSize: "0.8em" }}>▼</span>
            </button>

            {isTournamentDropdownOpen && (
              <div ref={dropdownRef} className="tournament-dropdown-menu">
                {tournaments
                  .sort((a, b) => b.sequence - a.sequence)
                  .map((tournament) => (
                    <button
                      key={tournament.id}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleTournamentSelection(tournament.id);
                      }}
                    >
                      <span className="icon">
                        {getSportEmoji(tournament.sport).emoji}
                      </span>
                      <span>{tournament.name}</span>
                    </button>
                  ))}
              </div>
            )}
          </div>{" "}
          {renderMenuItems(mainMenuItems)}
        </div>

        <div className="navbar-links secondary-menu">
          {renderMenuItems(secondaryMenuItems)}
          <div className="navbar-links main-menu">
            {user ? (
              <button onClick={handleLogout}>Logout</button>
            ) : (
              <GoogleLogin
                onSuccess={handleLogin}
                onError={() => {
                  console.log("Login Failed");
                }}
              />
            )}
          </div>
        </div>

        <button
          aria-label="Toggle menu"
          className="navbar-toggle"
          onClick={toggleMenu}
        >
          ☰
        </button>
      </div>
      {isOpen && (
        <div className="navbar-mobile">
          <div className="tournament-dropdown">
            <button
              onClick={() => setIsMobileDropdownOpen(!isMobileDropdownOpen)}
              className="tournament-button"
            >
              <span className="icon">
                {
                  getSportEmoji(
                    tournaments.find((t) => t.id === tournamentId)?.sport
                  ).emoji
                }
              </span>
              <span>
                {tournaments.find((t) => t.id === tournamentId)?.name ||
                  "Select Tournament"}
              </span>
              <span>▼</span>
            </button>

            {isMobileDropdownOpen && (
              <div className="tournament-dropdown-menu">
                {tournaments.map((tournament) => (
                  <button
                    key={tournament.id}
                    onClick={() =>
                      handleMobileTournamentSelection(tournament.id)
                    }
                  >
                    <span className="icon">
                      {getSportEmoji(tournament.sport).emoji}
                    </span>
                    <span>{tournament.name}</span>
                  </button>
                ))}
              </div>
            )}
          </div>

          {mainMenuItems.map(
            (item) =>
              (!item.authRequired || user) && (
                <React.Fragment key={item.path}>
                  <NavLink
                    to={item.path}
                    className={({ isActive }) => (isActive ? "active" : "")}
                    onClick={toggleMenu}
                  >
                    <span className="icon">{item.icon}</span> {item.label}
                  </NavLink>
                </React.Fragment>
              )
          )}

          {secondaryMenuItems.map(
            (item) =>
              (!item.authRequired || user) && (
                <NavLink
                  key={item.path}
                  to={
                    item.path === "/profile" && user
                      ? `/profile/${user.id}`
                      : item.path
                  }
                  className={({ isActive }) => (isActive ? "active" : "")}
                  onClick={toggleMenu}
                >
                  <span className="icon">{item.icon}</span> {item.label}
                </NavLink>
              )
          )}
          {user ? (
            <button onClick={handleLogout}>Logout</button>
          ) : (
            <GoogleLogin
              onSuccess={handleLogin}
              onError={() => {
                console.log("Login Failed");
              }}
            />
          )}
        </div>
      )}{" "}
    </nav>
  );
};

export default NavBar;
