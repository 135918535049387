import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useEmojiTooltip } from "./components/emojiUtils";

const Leaderboard = ({
  user,
  tournamentId,
  tournamentOver,
  isF1Tournament,
}) => {
  const [leaderboardData, setLeaderboardData] = useState({
    overall: [],
    rounds: {},
  });
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedRound, setSelectedRound] = useState("Overall");
  const [orderedRounds, setOrderedRounds] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const dropdownRef = useRef(null);
  const { renderPredefinedEmoji, renderCountryEmojis, renderF1TeamLogos } =
    useEmojiTooltip();

  const fetchLeaderboardData = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const [overallResponse, roundResponse] = await Promise.all([
        fetch(
          `${process.env.REACT_APP_API_URL}/api/leaderboard?tournamentId=${tournamentId}`
        ),
        fetch(
          `${process.env.REACT_APP_API_URL}/api/round-leaderboard?tournamentId=${tournamentId}`
        ),
      ]);

      if (!overallResponse.ok || !roundResponse.ok) {
        throw new Error("Failed to fetch leaderboard data");
      }

      const overallData = await overallResponse.json();
      const roundData = await roundResponse.json();

      if (typeof roundData !== "object" || roundData === null) {
        throw new Error("Invalid round leaderboard data");
      }

      const roundsWithIds = Object.entries(roundData).map(
        ([round, entries]) => ({
          round,
          id: entries[0]?.id || 0,
        })
      );

      const sortedRounds = roundsWithIds
        .sort((a, b) => a.id - b.id)
        .map(({ round }) => round);

      setLeaderboardData({ overall: overallData, rounds: roundData });
      setOrderedRounds(["Overall", ...sortedRounds]);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching leaderboard data:", error);
      setError("Failed to load leaderboard data. Please try again later.");
      setIsLoading(false);
    }
  }, [tournamentId]);

  useEffect(() => {
    fetchLeaderboardData();
  }, [fetchLeaderboardData]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const renderCellWithStar = useCallback(
    (value, maxValue) => (
      <TableCell align="right">
        {value === maxValue &&
          value !== 0 &&
          renderPredefinedEmoji({ emojiKey: "star" })}
        {value}
      </TableCell>
    ),
    [renderPredefinedEmoji]
  );

  const getMedalEmoji = useCallback(
    (rank) => {
      if (!tournamentOver) return "";
      switch (rank) {
        case 1:
          return renderPredefinedEmoji({ emojiKey: "goldMedal" });
        case 2:
          return renderPredefinedEmoji({ emojiKey: "silverMedal" });
        case 3:
          return renderPredefinedEmoji({ emojiKey: "bronzeMedal" });
        default:
          return "";
      }
    },
    [tournamentOver, renderPredefinedEmoji]
  );

  const renderCroissantEmoji = useCallback(
    (row) => {
      if (!tournamentOver || !row.croissant_count) return null;

      if ([2, 3, 4].includes(parseInt(tournamentId))) {
        if (row.croissant_count > 0) {
          const chocolateText =
            row.croissant_count === 1 ? "Chocolate" : "Chocolates";
          return renderPredefinedEmoji({
            emojiKey: "chocolate",
            customTooltip: `${
              row.croissant_count
            } Anonymous ${chocolateText} from ${
              row.croissant_count === 1 ? "a Friend" : "Friends"
            }`,
          });
        }
      } else if (row.is_croissant_leader) {
        return renderPredefinedEmoji({ emojiKey: "croissant" });
      }

      return null;
    },
    [tournamentOver, tournamentId, renderPredefinedEmoji]
  );

  const memoizedLeaderboardData = useMemo(() => {
    const data =
      selectedRound === "Overall"
        ? leaderboardData.overall
        : leaderboardData.rounds[selectedRound] || [];

    const maxValues = data.reduce(
      (acc, row) => ({
        winner_points: Math.max(acc.winner_points, row.winner_points),
        handicap_points: Math.max(acc.handicap_points, row.handicap_points),
        top_scorer_points: Math.max(
          acc.top_scorer_points,
          row.top_scorer_points
        ),
      }),
      { winner_points: 0, handicap_points: 0, top_scorer_points: 0 }
    );

    return { data, maxValues };
  }, [leaderboardData, selectedRound]);

  const handleRoundSelection = useCallback((round) => {
    setSelectedRound(round);
    setIsDropdownOpen(false);
  }, []);

  if (isLoading) {
    return <CircularProgress />;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div>
      <div className="rounds-dropdown" ref={dropdownRef}>
        <button
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          className="overall-button"
        >
          {selectedRound} ▼
        </button>
        {isDropdownOpen && (
          <div className="round-dropdown">
            {orderedRounds.map((round) => (
              <button key={round} onClick={() => handleRoundSelection(round)}>
                {round}
              </button>
            ))}
          </div>
        )}
      </div>
      <div className="table-container">
        <TableContainer component={Paper}>
          <Table aria-label="Leaderboard">
            <TableHead>
              <TableRow>
                <TableCell>Rank</TableCell>
                <TableCell>Name</TableCell>
                <TableCell align="right">Total Points</TableCell>
                <TableCell align="right">Winner Points</TableCell>
                <TableCell align="right">Handicap Points</TableCell>
                {!isF1Tournament && (
                  <TableCell align="right">Top Scorer Points</TableCell>
                )}
                {selectedRound === "Overall" && (
                  <TableCell align="center">Expertise</TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {memoizedLeaderboardData.data.map((row) => (
                <TableRow
                  className={`expandable-single-line-cell ${
                    user && row.user_id === user.id ? "highlighted-row" : ""
                  }`}
                  key={row.user_id}
                >
                  <TableCell>
                    <b>{row.rank}.</b>
                  </TableCell>
                  <TableCell>
                    <b>
                      {selectedRound === "Overall" &&
                        tournamentOver &&
                        getMedalEmoji(row.rank)}
                      <Link to={`/profile/${row.user_id}`}>{row.name}</Link>
                      {selectedRound === "Overall" && (
                        <>
                          {row.round_win_count > 0 &&
                            renderPredefinedEmoji({
                              emojiKey: "roundWinner",
                              count: row.round_win_count,
                              customTooltip: `${row.round_win_count} Round${
                                row.round_win_count > 1 ? "s" : ""
                              } Won`,
                            })}
                          {row.on_fire &&
                            !tournamentOver &&
                            renderPredefinedEmoji({
                              emojiKey: "onFire",
                              customTooltip: `${row.name} is on fire!`,
                            })}
                          {renderCroissantEmoji(row)}
                        </>
                      )}
                    </b>
                  </TableCell>
                  <TableCell align="right">
                    <b>{row.total_points}</b>
                  </TableCell>
                  {renderCellWithStar(
                    row.winner_points,
                    memoizedLeaderboardData.maxValues.winner_points
                  )}
                  {renderCellWithStar(
                    row.handicap_points,
                    memoizedLeaderboardData.maxValues.handicap_points
                  )}
                  {!isF1Tournament &&
                    renderCellWithStar(
                      row.top_scorer_points,
                      memoizedLeaderboardData.maxValues.top_scorer_points
                    )}
                  {selectedRound === "Overall" && (
                    <TableCell align="center">
                      <Tooltip arrow>
                        <>
                          {isF1Tournament
                            ? renderF1TeamLogos(row.expert_teams)
                            : renderCountryEmojis(row.expert_countries)}
                        </>
                      </Tooltip>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default Leaderboard;
