import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { Link } from "react-router-dom";
import JokerIcon from "./components/JokerIcon";
import { useEmojiTooltip } from "./components/emojiUtils";

function RaceDetails({ tournamentId, user }) {
  const { id } = useParams();
  const [race, setRace] = useState(null);
  const [predictions, setPredictions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { renderPredefinedEmoji, renderApiEmojis } = useEmojiTooltip();

  const raceHasStarted = race && new Date(race.qualifying_date) <= new Date();
  const raceHasEnded = () => {
    return race.teams.some(
      (team) => team.driver1_position !== null || team.driver2_position !== null
    );
  };

  const formatPosition = (position) => {
    if (position === null && raceHasEnded()) {
      return "NC";
    } else if (position !== null) {
      return `P${position}`;
    }
    return "";
  };
  useEffect(() => {
    async function fetchRaceAndPredictions() {
      try {
        const [raceResponse, predictionsResponse] = await Promise.all([
          fetch(
            `${process.env.REACT_APP_API_URL}/api/f1/races/${id}?tournamentId=${tournamentId}`
          ),
          fetch(
            `${process.env.REACT_APP_API_URL}/api/f1/races/${id}/predictions?tournamentId=${tournamentId}`
          ),
        ]);

        if (!raceResponse.ok || !predictionsResponse.ok) {
          throw new Error("Failed to fetch data");
        }

        const [raceData, predictionsData] = await Promise.all([
          raceResponse.json(),
          predictionsResponse.json(),
        ]);

        setRace(raceData);
        setPredictions(predictionsData.predictions);
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    }

    fetchRaceAndPredictions();
  }, [id, tournamentId]);

  const formatF1Time = (dateTimeString) => {
    const date = new Date(dateTimeString);
    return date.toLocaleString(undefined, {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
      timeZoneName: "short",
    });
  };

  const getF1Countdown = (qualifyingDate, raceDate) => {
    // ... (keep your existing countdown logic)
  };

  const renderPredictionTable = () => {
    const groupedPredictions = predictions.reduce((acc, prediction) => {
      if (!acc[prediction.team_name]) {
        acc[prediction.team_name] = [];
      }
      acc[prediction.team_name].push(prediction);
      return acc;
    }, {});
    const sortedTeams = Object.keys(groupedPredictions).sort();

    return (
      <div className="table-container">
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow className="expandable-single-line-cell">
                <TableCell>User</TableCell>
                <TableCell></TableCell>
                <TableCell>Total Points</TableCell>
                <TableCell colSpan={2}>Winner</TableCell>
                <TableCell colSpan={2}>Handicap</TableCell>
                <TableCell></TableCell>
              </TableRow>
              <TableRow className="expandable-single-line-cell">
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell>Prediction</TableCell>
                <TableCell>Points</TableCell>
                <TableCell>Prediction</TableCell>
                <TableCell>Points</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedTeams.map((teamName) => {
                const team = race.teams.find((t) => t.team_name === teamName);
                if (!team) {
                  console.warn(`Team not found: ${teamName}`);
                  return null;
                }
                return (
                  <React.Fragment key={teamName}>
                    <TableRow>
                      <TableCell colSpan={8} className="font-bold bg-gray-100">
                        <span>
                          {team.team_emoji}
                          <b> {team.team_name}</b> <br></br> {team.driver1_flag}{" "}
                          {team.driver1} {formatPosition(team.driver1_position)}{" "}
                          • {team.driver2_flag} {team.driver2}{" "}
                          {formatPosition(team.driver2_position)}
                        </span>
                      </TableCell>
                    </TableRow>
                    {groupedPredictions[teamName].map((prediction) => {
                      const isDriver1Predicted =
                        prediction.handicap_prediction === team.driver1;
                      const displayHandicap = isDriver1Predicted
                        ? team.handicap
                        : -team.handicap;

                      return (
                        <TableRow
                          key={prediction.id || prediction.user_name}
                          className={`expandable-single-line-cell ${
                            prediction.user_id === user?.id
                              ? "highlighted-row"
                              : ""
                          }`}
                        >
                          <TableCell>
                            <b>
                              <Link to={`/profile/${prediction.user_id}`}>
                                {prediction.user_name}
                              </Link>
                            </b>
                          </TableCell>
                          <TableCell>
                            {prediction.is_double_points && (
                              <JokerIcon isActive={true} isButton={false} />
                            )}
                          </TableCell>
                          <TableCell>
                            <b>
                              {raceHasStarted &&
                              prediction.total_points !== undefined
                                ? prediction.total_points
                                : ""}
                            </b>
                          </TableCell>
                          <TableCell>
                            {prediction.winner_prediction || ""}
                          </TableCell>
                          <TableCell>
                            {raceHasStarted &&
                            prediction.winner_points !== undefined
                              ? prediction.winner_points
                              : ""}
                          </TableCell>
                          <TableCell>
                            {prediction.handicap_prediction
                              ? `${prediction.handicap_prediction} (${
                                  displayHandicap > 0 ? "+" : ""
                                }${displayHandicap})`
                              : ""}
                          </TableCell>
                          <TableCell>
                            {raceHasStarted &&
                            prediction.handicap_points !== undefined
                              ? prediction.handicap_points
                              : ""}
                          </TableCell>
                          <TableCell>
                            {prediction.emoji &&
                              renderApiEmojis(prediction.emoji)}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </React.Fragment>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  };

  const renderPredictionStatistics = () => {
    const teams = race.teams || [];

    const doublePointsData = teams.map((team) => ({
      team_name: team.team_name,
      team_emoji: team.team_emoji,
      count: countDoublePredictions(team.team_name),
      percentage: calculateDoublePercentage(team.team_name),
    }));

    doublePointsData.sort((a, b) => b.count - a.count);
    const sortedTeams = [...teams].sort((a, b) =>
      a.team_name.localeCompare(b.team_name)
    );
    return (
      <div className="prediction-tables-container">
        {sortedTeams.map((team) => (
          <div key={team.team_name} className="prediction-table">
            <h3>
              {team.team_emoji} {team.team_name}
            </h3>
            <TableContainer component={Paper}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Driver</TableCell>
                    <TableCell>🏁</TableCell>
                    <TableCell>Winner</TableCell>
                    <TableCell>Handicap</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {[team.driver1, team.driver2].map((driver, index) => {
                    const isDriver1 = index === 0;
                    const position = isDriver1
                      ? team.driver1_position
                      : team.driver2_position;
                    const teammatePosition = isDriver1
                      ? team.driver2_position
                      : team.driver1_position;
                    const driverHandicap = isDriver1
                      ? team.handicap
                      : -team.handicap;

                    const beatTeammate =
                      raceHasEnded() &&
                      ((position !== null && teammatePosition === null) ||
                        (position !== null &&
                          teammatePosition !== null &&
                          position < teammatePosition));

                    const beatTeammateWithHandicap =
                      raceHasEnded() &&
                      ((position !== null && teammatePosition === null) ||
                        (position !== null &&
                          teammatePosition !== null &&
                          (isDriver1
                            ? position - team.handicap < teammatePosition
                            : position + team.handicap < teammatePosition)));

                    return (
                      <TableRow key={driver}>
                        <TableCell>
                          <div className="driver-cell">
                            <span className="driver-name">{driver}</span>
                            <span className="driver-handicap">
                              ({driverHandicap > 0 ? "+" : ""}
                              {driverHandicap})
                            </span>
                          </div>
                        </TableCell>{" "}
                        <TableCell>{formatPosition(position)}</TableCell>
                        <TableCell>
                          {countPredictions("winner_prediction", driver)} (
                          {calculatePercentage(
                            "winner_prediction",
                            driver,
                            team.team_name
                          )}
                          %)
                          {beatTeammate && (
                            <span style={{ marginLeft: "4px" }}>
                              {renderPredefinedEmoji({ emojiKey: "correct" })}
                            </span>
                          )}
                        </TableCell>
                        <TableCell>
                          {countPredictions("handicap_prediction", driver)} (
                          {calculatePercentage(
                            "handicap_prediction",
                            driver,
                            team.team_name
                          )}
                          %)
                          {beatTeammateWithHandicap && (
                            <span style={{ marginLeft: "4px" }}>
                              {renderPredefinedEmoji({ emojiKey: "correct" })}
                            </span>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        ))}
        <div className="prediction-table">
          <h3>Double Points Selections</h3>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Team</TableCell>
                  <TableCell align="right">Count</TableCell>
                  <TableCell align="right">Percentage</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {doublePointsData.map(
                  ({ team_name, team_emoji, count, percentage }) => (
                    <TableRow key={team_name}>
                      <TableCell>
                        {team_emoji} {team_name}
                      </TableCell>
                      <TableCell align="right">{count}</TableCell>
                      <TableCell align="right">{percentage}%</TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    );
  };

  const calculatePercentage = (predictionType, driverName, teamName) => {
    const count = countPredictions(predictionType, driverName);
    const teamTotal = predictions.filter(
      (p) => p.team_name === teamName
    ).length;
    return teamTotal > 0 ? Math.round((count / teamTotal) * 100) : 0;
  };

  const calculateDoublePercentage = (teamName) => {
    const count = countDoublePredictions(teamName);
    const teamTotal = predictions.filter(
      (p) => p.team_name === teamName
    ).length;
    return teamTotal > 0 ? Math.round((count / teamTotal) * 100) : 0;
  };

  const countPredictions = (predictionType, driverName) => {
    const count = predictions.filter(
      (p) => p[predictionType] === driverName
    ).length;

    return count;
  };

  const countDoublePredictions = (teamName) => {
    const count = predictions.filter(
      (p) => p.team_name === teamName && p.is_double_points
    ).length;

    return count;
  };

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!race) return <div>Race not found</div>;

  return (
    <>
      <div className="RaceDetails">
        <h2>
          {race.gp_emoji} {race.name}
        </h2>
        <p>
          Qualifying: {formatF1Time(race.qualifying_date)}
          <br />
          Race: {formatF1Time(race.date)}
          <br />
          {getF1Countdown(race.qualifying_date, race.date)}
        </p>
      </div>
      <h2>Predictions</h2>
      {renderPredictionTable()}
      {raceHasStarted && (
        <>
          <h2>Prediction Statistics</h2>
          {renderPredictionStatistics()}
        </>
      )}
    </>
  );
}

export default RaceDetails;
