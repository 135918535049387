import React, { useEffect, useCallback, useRef } from "react";

const RoundsCarousel = ({ rounds, currentRound, setCurrentRound }) => {
    const carouselRef = useRef(null);
  
    const scrollToActiveButton = useCallback(() => {
      if (carouselRef.current) {
        const activeButton = carouselRef.current.querySelector(".active");
        if (activeButton) {
          activeButton.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "center",
          });
        }
      }
    }, []);
  
    useEffect(() => {
      const timeoutId = setTimeout(() => {
        scrollToActiveButton();
      }, 0);
  
      return () => clearTimeout(timeoutId);
    }, [currentRound, rounds, scrollToActiveButton]);
  
    const nextRound = () => {
      const currentIndex = rounds.indexOf(currentRound);
      if (currentIndex < rounds.length - 1) {
        setCurrentRound(rounds[currentIndex + 1]);
      }
    };
  
    const prevRound = () => {
      const currentIndex = rounds.indexOf(currentRound);
      if (currentIndex > 0) {
        setCurrentRound(rounds[currentIndex - 1]);
      }
    };
  
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          // marginBottom: "20px",
        }}
      >
        <button
          onClick={prevRound}
          style={{
            fontSize: "1em",
            background: "none",
            border: "none",
            color: rounds.indexOf(currentRound) === 0 ? "#cccccc" : "#333333",
            cursor: rounds.indexOf(currentRound) === 0 ? "default" : "pointer",
            padding: "5px 10px",
            flexShrink: 0,
          }}
          disabled={rounds.indexOf(currentRound) === 0}
        >
          &lt;
        </button>
        <div
          ref={carouselRef}
          style={{
            display: "flex",
            overflowX: "auto",
            scrollSnapType: "x mandatory",
            scrollBehavior: "smooth",
            WebkitOverflowScrolling: "touch",
            scrollbarWidth: "none",
            msOverflowStyle: "none",
            maxWidth: "700px",
            padding: "0 5px",
          }}
        >
          {rounds.map((round) => (
            <button
              key={round}
              onClick={() => setCurrentRound(round)}
              className={round === currentRound ? "active" : ""}
              style={{
                background: round === currentRound ? "#007bff" : "none",
                border: "none",
                color: round === currentRound ? "white" : "#333333",
                cursor: "pointer",
                margin: "0 4px",
                whiteSpace: "nowrap",
                borderRadius: "4px",
                flexShrink: 0,
                fontFamily: '"Titillium Web", sans-serif',
                padding: "8px 12px",
                scrollSnapAlign: "center",
              }}
            >
              {round}
            </button>
          ))}
        </div>
        <button
          onClick={nextRound}
          style={{
            fontSize: "1em",
            background: "none",
            border: "none",
            color:
              rounds.indexOf(currentRound) === rounds.length - 1
                ? "#cccccc"
                : "#333333",
            cursor:
              rounds.indexOf(currentRound) === rounds.length - 1
                ? "default"
                : "pointer",
            padding: "5px 10px",
            flexShrink: 0,
          }}
          disabled={rounds.indexOf(currentRound) === rounds.length - 1}
        >
          &gt;
        </button>
      </div>
    );
  };

export default RoundsCarousel;